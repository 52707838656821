import React, { useEffect } from "react"
export default () => {
  useEffect(() => {
    window.addEventListener('scroll', () => {
      var nav = document.querySelector('nav')
      nav.classList.toggle('shadow-xl', window.scrollY > 0)
    })
  })
  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-white sticky-top">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img class="object-contain h-12" src="/static/header_logo.png" />
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse align-items-center" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Services</a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Case Studies</a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Blogs</a>
            </li>
            <button className="inline-flex items-center bg-teal-200 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base align-self-center  text-center">
              Contact Us
            </button>
          </ul>

        </div>
      </div>
    </nav>

  )
}
