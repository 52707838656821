import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import SEO from "./SEO";

const Layout = ({ children }) => {
  return (
    <>
      <main>
        <SEO />
        <Header />
        {children}
        <Footer />
      </main>
    </>
  );
};

export default Layout;
